// Regex Patterns
export const CLAIM_STRING_REGEX_STRICT = "^(AU|PR|GL|CC)-([0-9]{7})-([0-9]{6})$";
const CLAIM_STRING_REGEX =
  "(AU|PR|GL|CC|au|pr|gl|cc|Au|Pr|Gl|Cc)" + "(?:\\s|\\.|-)?([0-9]{7})?(?:\\s|\\.|-)?([0-9]{6})";
export const GLOBAL_CLAIM_STRING_REGEX = new RegExp(CLAIM_STRING_REGEX, "g");
export const LOCAL_CLAIM_STRING_REGEX = new RegExp(CLAIM_STRING_REGEX + "$");

export const ENV_MAP = [{ value: "L1-1" }, { value: "L2-1" }, { value: "L3-1" }, { value: "L3-5" }, { value: "L4-1" }];

export const DEFAULT_ADJUSTER_ASSIGNMENT = [{ key: "default_user:-1", value: "Default assignment" }];

export const CATEGORIES = [
  { key: "damages_ext", value: "Damages" },
  { key: "injury_ext", value: "Injury" },
  { key: "investigation_ext", value: "Investigation" },
  { key: "legal_ext", value: "Legal" },
  { key: "salvage_ext", value: "Salvage" },
  { key: "subrogation_ext", value: "Subrogation" },
];

export const DOCUMENT_SUBTYPE_ENUM = {
  unspecified: "Unspecified",
  adjusterappraisal_ext: "Adjuster Appraisal",
  arbitration_ext: "Arbitration",
  attorneycorrespondence_ext: "Attorney Correspondence",
  audiovideo_ext: "Audio/Video",
  billrecordreview_ext: "Bill/record/review/itemization",
  claimInquiry_ext: "Claim Inquiry",
  claimsearch_ext: "ClaimSearch/PILR",
  claimxperience_ext: "ClaimXperience",
  condoleaseagreement_ext: "Condo/Lease Agreement",
  contentsspoilage_ext: "Contents/Spoilage",
  demo_propertytax_ext: "Demo/Property Tax",
  estimateappraisalreceipt_ext: "Estimate/Appraisal/Receipt",
  expertreport_ext: "Expert Report",
  explanationreview_ext: "Explanation of Review",
  fnol_ext: "FNOL",
  hsbreinsurance_ext: "HSB/Reinsurance",
  independantmedicaleval_ext: "Independent Medical Evaluation",
  insurancevalue_ext: "Insurance To Value",
  internalreport_ext: "Internal Report",
  itelmaterialevaluation_ext: "ITEL/Material Evaluation",
  legaldocs_ext: "Legal Documents",
  legalfiling_ext: "Legal Filing",
  lettercorrespondence_ext: "Letter/Correspondence",
  medicalauth_ext: "Medical Authorization",
  medicalbill_ext: "Medical Bill",
  medicalrecord_ext: "Medical Record",
  officalreport_ext: "Official Report",
  photos_ext: "Photos",
  policefirereport_ext: "Police/Fire Report",
  property_ext: "Property",
  recordedstatement_ext: "Recorded Statement",
  release_ext: "Release",
  repairestimate_ext: "Repair Estimate",
  salvagedocuments_ext: "Salvage Documents",
  salvageinvoice_ext: "Salvage Invoice",
  salvagepayment_ext: "Salvage Payment",
  scopediagram_ext: "Scope/Diagram",
  signreturn_ext: "Sign and Return Form",
  siu_ext: "SIU",
  subrosalvage_ext: "Subro/Salvage",
  summoncomplaint_ext: "Summon and Complaint",
  timeelement_ext: "Time Element",
  titlesearchreport_ext: "Title Search Report",
  wageloss_ext: "Wage Loss",
  weatherreport_ext: "Weather Report",
};

export const SUBTYPE_MAP = {
  unspecified: ["damages_ext", "injury_ext", "investigation_ext", "legal_ext", "salvage_ext", "subrogation_ext"],
  adjusterappraisal_ext: ["damages_ext"],
  arbitration_ext: ["subrogation_ext"],
  attorneycorrespondence_ext: ["legal_ext"],
  audiovideo_ext: ["investigation_ext"],
  billrecordreview_ext: ["damages_ext", "injury_ext"],
  claimInquiry_ext: ["investigation_ext"],
  claimsearch_ext: ["investigation_ext"],
  claimxperience_ext: ["investigation_ext"],
  contentsspoilage_ext: ["damages_ext"],
  demo_propertytax_ext: ["investigation_ext"],
  estimateappraisalreceipt_ext: [
    "damages_ext",
    "injury_ext",
    "investigation_ext",
    "legal_ext",
    "salvage_ext",
    "subrogation_ext",
  ],
  expertreport_ext: ["investigation_ext"],
  explanationreview_ext: ["injury_ext"],
  fnol_ext: ["investigation_ext"],
  hsbreinsurance_ext: ["investigation_ext"],
  independantmedicaleval_ext: ["injury_ext"],
  insurancevalue_ext: ["investigation_ext"],
  internalreport_ext: ["damages_ext"],
  itelmaterialevaluation_ext: ["investigation_ext"],
  legaldocs_ext: ["subrogation_ext"],
  legalfiling_ext: ["damages_ext", "injury_ext", "investigation_ext", "legal_ext", "salvage_ext", "subrogation_ext"],
  lettercorrespondence_ext: [
    "damages_ext",
    "injury_ext",
    "investigation_ext",
    "legal_ext",
    "salvage_ext",
    "subrogation_ext",
  ],
  medicalauth_ext: ["injury_ext"],
  medicalbill_ext: ["injury_ext"],
  medicalrecord_ext: ["injury_ext"],
  officalreport_ext: ["damages_ext", "injury_ext", "investigation_ext"],
  photos_ext: ["damages_ext", "investigation_ext"],
  policefirereport_ext: ["investigation_ext"],
  property_ext: ["subrogation_ext"],
  recordedstatement_ext: ["investigation_ext"],
  release_ext: ["injury_ext"],
  repairestimate_ext: ["damages_ext"],
  salvagedocuments_ext: ["salvage_ext"],
  salvageinvoice_ext: ["salvage_ext"],
  salvagepayment_ext: ["salvage_ext"],
  scopediagram_ext: ["investigation_ext"],
  signreturn_ext: ["damages_ext", "injury_ext", "investigation_ext"],
  siu_ext: ["legal_ext"],
  subrosalvage_ext: ["damages_ext"],
  summoncomplaint_ext: ["legal_ext"],
  timeelement_ext: ["damages_ext"],
  titlesearchreport_ext: ["investigation_ext"],
  wageloss_ext: ["injury_ext"],
  weatherreport_ext: ["investigation_ext"],
};

export const CONTENT_TYPE_MAP = {
  // Microsoft Office Formats
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow": "ppsx",
  "application/vnd.ms-excel": "xls",
  "application/msword": "doc",
  "application/pdf": "pdf",

  // Outlook-Specific Formats
  "application/vnd.ms-outlook": "msg", // Outlook email message
  "text/calendar": "ics", // Calendar event
  "text/x-vcard": "vcf", // Contact card

  // Email and Messaging Formats
  "message/rfc822": "eml", // Standard email format (RFC 822)
  "application/mbox": "mbox", // Email mailbox format
  "application/pkcs7-mime": "p7m", // Encrypted email

  // Image Formats
  "image/jpeg": "jpg",
  "image/png": "png",
  "image/gif": "gif",
  "image/tiff": "tiff",
  "image/bmp": "bmp",
  "image/webp": "webp",

  // Plain and HTML Text
  "text/plain": "txt",
  "text/html": "html",
  "text/csv": "csv",
  "text/css": "css",
  "text/javascript": "js",

  // Additional Common Formats
  "application/json": "json",
  "application/xml": "xml",
  "application/zip": "zip",
  "application/x-tar": "tar",
  "application/x-rar-compressed": "rar",
  "audio/mpeg": "mp3",
  "audio/wav": "wav",
  "video/mp4": "mp4",
  "video/x-msvideo": "avi",
};
