import React from "react";
import { Button, Typography, Box, Icon } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

function navigateToOtherURL(claimNumber, env) {
  let linkUrl = "";
  if (env !== "L4-1") {
    linkUrl = `https://ent-claimcenter-${env}.apps.stateauto.com:8443/cc/ClaimSummaryLink.do?claimNumber=`;
  } else {
    linkUrl = "https://ent-claimcenter.apps.stateauto.com/cc/ClaimSummaryLink.do?claimNumber=";
  }
  linkUrl = linkUrl + claimNumber;
  window.open(linkUrl);
}

class Success extends React.Component {
  render() {
    const { ClaimNumber, environment } = this.props;

    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <Icon color="success" fontSize="large">
          <CheckCircleOutline fontSize="large" />
        </Icon>
        <Typography variant="h5" align="center" mt={2}>
          Success! Click the button below to navigate to claim.
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigateToOtherURL(ClaimNumber, environment)} pt={3}>
          Navigate to Claim
        </Button>
      </Box>
    );
  }
}

export default Success;
