import * as React from "react";
import PropTypes from "prop-types";
import { Box, TextField } from "@mui/material";

export default class CustomTextField extends React.Component {
  render() {
    const { label, error, defaultValue, fontSize, onBlurFunc, inputProps, helperText } = this.props;
    let modifiedLabel = label.replaceAll(" ", "").toLocaleLowerCase();

    return (
      <Box autoComplete="off" mb={3}>
        <TextField
          id={`${modifiedLabel}-id-auto-select`}
          fullWidth
          label={label}
          variant="standard"
          multiline
          defaultValue={defaultValue}
          InputProps={{
            ...inputProps,
            style: { fontSize: fontSize },
          }}
          error={error}
          onBlur={(e) => onBlurFunc && onBlurFunc(e, modifiedLabel)}
          InputLabelProps={{ style: { fontSize: fontSize } }}
          helperText={helperText}
        />
      </Box>
    );
  }
}

CustomTextField.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  fontSize: PropTypes.number,
  func: PropTypes.func,
  inputProps: PropTypes.object,
  helperText: PropTypes.string,
};
