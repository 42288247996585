import * as React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import PropTypes from "prop-types";

export default class CustomAutoComplete extends React.Component {
  render() {
    const { label, helperText, onBlurFunc, fontSize, valueMap, error, defaultValue } = this.props;
    let modifiedLabel = label.replaceAll(" ", "").toLocaleLowerCase();

    return (
      <Box autoComplete="off" mb={3}>
        <Autocomplete
          id={`${modifiedLabel}-select`}
          freeSolo
          ListboxProps={{
            sx: { fontSize: fontSize },
          }}
          sx={{
            "& .MuiAutocomplete-input": {
              fontSize: fontSize,
            },
          }}
          defaultValue={defaultValue}
          options={valueMap.map((option) => option.value)}
          renderInput={(params) => (
            <TextField
              error={error}
              {...params}
              helperText={helperText}
              label={label}
              onBlur={(e) => onBlurFunc(e, modifiedLabel)}
            />
          )}
        />
      </Box>
    );
  }
}

CustomAutoComplete.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  fontSize: PropTypes.number,
  onBlurFunc: PropTypes.func,
  valueMap: PropTypes.array,
  error: PropTypes.bool,
  defaultValue: PropTypes.string,
};
