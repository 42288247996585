import {
  DEFAULT_ADJUSTER_ASSIGNMENT,
  DOCUMENT_SUBTYPE_ENUM,
  SUBTYPE_MAP,
  CLAIM_STRING_REGEX_STRICT,
  GLOBAL_CLAIM_STRING_REGEX,
  LOCAL_CLAIM_STRING_REGEX,
  CONTENT_TYPE_MAP,
} from "./constants";

export function normalizeClaimNumber(candidateClaimNumber) {
  let subMatches = candidateClaimNumber.match(LOCAL_CLAIM_STRING_REGEX);
  if (!subMatches) {
    return "";
  }
  let claimType = subMatches[1].toUpperCase();
  let claimMiddle = subMatches[2];
  let claimEnd = subMatches[3];
  if (!claimMiddle) {
    claimMiddle = "0000000";
  }
  return claimType + "-" + claimMiddle + "-" + claimEnd;
}

function returnTopFiveMostCommonNumbers(resultsWithCounts) {
  let results = [];
  let resultKeys = Object.keys(resultsWithCounts);
  while (resultKeys.length > 0 && results.length < 5) {
    let highestCountResult = 0;
    let highestCountIndex = -1;
    let highestCountResultNumber = "";

    for (let jdx = 0; jdx < resultKeys.length; jdx += 1) {
      highestCountResult = resultsWithCounts[resultKeys[jdx]];
      highestCountIndex = jdx;
      highestCountResultNumber = resultKeys[jdx];
    }
    resultKeys = resultKeys.filter(function (value, index, arr) {
      return index != highestCountIndex;
    });
    results.push(highestCountResultNumber);
  }
  return results;
}

export function normalizeClaimNumbersAndReturnInSortedOrder(stringContent) {
  let results = [];
  let resultsWithCounts = {};
  let matchRes = stringContent.match(GLOBAL_CLAIM_STRING_REGEX);
  if (!matchRes || matchRes.length === 0) {
    return results;
  }
  for (let idx = 0; idx < matchRes.length; idx += 1) {
    let subMatch = matchRes[idx];
    let claimNumber = normalizeClaimNumber(subMatch);
    if (claimNumber) {
      if (claimNumber in resultsWithCounts) {
        resultsWithCounts[claimNumber] = resultsWithCounts[claimNumber] + 1;
      } else {
        resultsWithCounts[claimNumber] = 1;
      }
    }
  }
  results = returnTopFiveMostCommonNumbers(resultsWithCounts);
  return results;
}

export function checkIsClaimnumberInvalid(claimNumber) {
  if (claimNumber.match(CLAIM_STRING_REGEX_STRICT)) {
    return false;
  } else {
    let normalizedClaimNumber = normalizeClaimNumber(claimNumber);
    if (normalizedClaimNumber) {
      return false;
    } else {
      return true;
    }
  }
}

export function checkIsInvalid(fieldValue) {
  if (!fieldValue) {
    return true;
  } else {
    return false;
  }
}

function appendExtension(filename, contentType) {
  if (!contentType) {
    return filename;
  }

  const extension = CONTENT_TYPE_MAP[contentType] || contentType.split("/")[1];

  if (!filename.includes(".") && extension) {
    return `${filename}.${extension}`;
  } else {
    return filename;
  }
}

export function handleAttachments(item) {
  let attachments = [];
  if (item.attachments.length > 0) {
    for (let i = 0; i < item.attachments.length; i++) {
      const filename = appendExtension(item.attachments[i].name, item.attachments[i].contentType);
      const options = {
        asyncContext: {
          currentItem: item,
          obj: {
            id: item.attachments[i].id,
            name: filename,
            size: item.attachments[i].size,
            mimeType: item.attachments[i].contentType,
            isChecked: true,
          },
          attachments,
        },
      };
      item.getAttachmentContentAsync(item.attachments[i].id, options, handleAttachmentsCallback);
    }
  }
  return attachments;
}

function handleAttachmentsCallback(result) {
  let attObject = result.asyncContext.obj;
  switch (result.value.format) {
    case Office.MailboxEnums.AttachmentContentFormat.Base64:
      attObject["hash"] = result.value.content;
      result.asyncContext.attachments.push(attObject);
      break;
    case Office.MailboxEnums.AttachmentContentFormat.Eml:
      console.log("Unhandled email item attachment.");
      // attObject["hash"] = convertToBase64(result.value.content);
      // const emailContentType = "message/rfc822";
      // if (!attObject["mimeType"]) {
      //   attObject["mimeType"] = emailContentType;
      //   attObject["name"] = appendExtension(attObject["name"], emailContentType);
      // }
      // result.asyncContext.attachments.push(attObject);
      break;
    case Office.MailboxEnums.AttachmentContentFormat.ICalendar:
      console.log("Unhandled .icalender attachment.");
      break;
    case Office.MailboxEnums.AttachmentContentFormat.Url:
      console.log("Unhandled cloud attachment.");
      break;
    default:
      console.log("Unhandled formats that are not supported.");
  }
}

function isProd(env) {
  return env.toLowerCase().slice(0, 2) == "l4";
}

export function getApiKeyAndUrl(env) {
  if (isProd(env)) {
    return {
      apiKey: process.env.api_key,
      apiUrl: process.env.api_url,
    };
  } else {
    return {
      apiKey: process.env.dev_api_key,
      apiUrl: process.env.dev_api_url,
    };
  }
}

function splitStringIntoChunks(s, chunkSize) {
  let startStrIdx = 0;
  let resParts = [];
  let minChunkSize = 500000;
  if (chunkSize < minChunkSize) {
    chunkSize = minChunkSize;
  }
  while (startStrIdx < s.length) {
    resParts.push(s.substring(startStrIdx, startStrIdx + chunkSize));
    startStrIdx += chunkSize;
  }
  console.log("Length of the result parts: " + resParts.length);
  return resParts;
}

async function fetchMultipleAwsS3UrlsForPost(numUrls, env) {
  let awsApiData = getApiKeyAndUrl(env);
  let apiKey = awsApiData.apiKey;
  let awsUrl = awsApiData.apiUrl + "?numUrls=" + numUrls;

  let awsHeaders = { "x-api-key": apiKey };
  let fetchParameters = {
    method: "GET",
    headers: awsHeaders,
  };

  try {
    const response = await fetch(awsUrl, fetchParameters);
    return await response.json();
  } catch (error) {
    throw new Error("Error in Fetching response");
  }
}

async function notifyServiceOfS3Upload(s3KeyArr, env) {
  let awsApiData = getApiKeyAndUrl(env);
  let apiKey = awsApiData.apiKey;
  let awsUrl = awsApiData.apiUrl;
  let awsHeaders = { "x-api-key": apiKey };
  let postParameters = {
    method: "POST",
    headers: awsHeaders,
    body: JSON.stringify(s3KeyArr),
  };

  try {
    const response = await fetch(awsUrl, postParameters);
    const data = await response.json();
    console.log("Response: ", data);
    return data;
  } catch (error) {
    throw new Error("Error notifying service of S3 upload");
  }
}

export async function postMultipleObjectsToS3(jsonContentAsString, env) {
  try {
    let jsonChunks = splitStringIntoChunks(jsonContentAsString, 4000000);
    let numChunks = jsonChunks.length;

    const urls = await fetchMultipleAwsS3UrlsForPost(numChunks, env);

    let requests = urls.map((url, idx) => ({
      url,
      method: "PUT",
      body: jsonChunks[idx],
    }));

    const responses = await Promise.all(requests.map(fetchS3Url));

    let s3KeyArr = responses
      .map((response) => {
        if (response.status === 200) {
          return extractS3Key(response.url);
        } else {
          return null;
        }
      })
      .filter(Boolean);

    console.log("Metadata file: " + JSON.stringify(s3KeyArr));

    // Notify service of successful uploads
    let notifyResponse = await notifyServiceOfS3Upload(s3KeyArr, env);

    return notifyResponse;
  } catch (error) {
    console.error(error);
    throw new Error("Error in Post Multiple Objects To S3");
  }
}

async function fetchS3Url(request) {
  try {
    const response = await fetch(request.url, {
      method: request.method,
      body: request.body,
    });

    if (!response.ok) {
      throw new Error("Request failed with status: " + response.status);
    }

    return response;
  } catch (error) {
    console.log("Error in fetchS3Url: ", error);
    throw error;
  }
}

function extractS3Key(url) {
  return url.split("?")[0].split("/")[3];
}

export async function getAdjusterDetails(claimNumber, env) {
  let tempEnv = "";
  let tempInputAdjusterAssign = DEFAULT_ADJUSTER_ASSIGNMENT;

  try {
    let tempClaimNumber = claimNumber.toString().replaceAll("-", "");
    tempEnv = env.toString().replaceAll("-", "");

    let awsApiData = getApiKeyAndUrl(env);
    let apiKey = awsApiData.apiKey;
    let awsUrl = awsApiData.apiUrl + "?adjusterClaimNumberLookup=" + tempClaimNumber;
    awsUrl = awsUrl + "&env=" + tempEnv;
    let awsHeaders = { "x-api-key": apiKey };
    let fetchParameters = {
      method: "get",
      headers: awsHeaders,
    };

    let response = await fetch(awsUrl, fetchParameters);
    let adjusterResults = JSON.parse(await response.json());

    if (adjusterResults && adjusterResults.Message) {
      let tempMessages = JSON.parse(adjusterResults.Message);
      if (tempMessages) {
        for (var indx = 0; indx < tempMessages.length; indx++) {
          var tempPair = tempMessages[indx];
          tempInputAdjusterAssign.push({
            value: tempPair[0],
            key: `${tempPair[1]}:${tempPair[2]}`,
          });
        }
      }
    }
    console.log({ "Input for Adjusters": tempInputAdjusterAssign });
  } catch (error) {
    console.log("Caught exception. adjusterLookupException: ", error);
  }

  return tempInputAdjusterAssign;
}

export function getDocSubType(title) {
  let keys = Object.keys(SUBTYPE_MAP);
  let tmp_subtypes = [];
  let doc_type = [];

  keys.forEach(function (key) {
    let value = SUBTYPE_MAP[key];
    if (value.includes(title)) {
      tmp_subtypes.push(key);
      doc_type.push({ key: key, value: DOCUMENT_SUBTYPE_ENUM[key] });
    }
  });

  return doc_type;
}

export function parseSelectedAdjusterAndExposureId(adj) {
  let selectedAdjuster = "default_user";
  let selectedExposureId = "-1";

  if (adj) {
    let selectedAdjusterAndExposureIdSplitParts = adj.split(":");
    if (selectedAdjusterAndExposureIdSplitParts.length === 2) {
      selectedAdjuster = selectedAdjusterAndExposureIdSplitParts[0];
      selectedExposureId = selectedAdjusterAndExposureIdSplitParts[1];
    }
  }

  return {
    selectedAdjuster: selectedAdjuster,
    selectedExposureId: selectedExposureId,
  };
}

export function getUsernameFromEmail(email) {
  const atIndex = email.indexOf("@");
  if (atIndex !== -1) {
    return email.substring(0, atIndex);
  }
  return email;
}

export function getDomainFromEmail(email) {
  const atIndex = email.indexOf("@");
  if (atIndex !== -1) {
    return email.substring(atIndex + 1);
  }
  return email;
}

export function isValidEnv(input) {
  const pattern = /^[lL][1-4]-(?:[1-9]|1[0-9]|2[0-9])$/;
  return !pattern.test(input);
}

export function convertToBase64(input) {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const binaryString = Array.from(data)
    .map((byte) => String.fromCharCode(byte))
    .join("");
  return btoa(binaryString);
}

// Replacing the Escape characters into HTML equivalent
function escapeHtml(text) {
  const map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#039;",
  };
  return text.replace(/[&<>"']/g, function (m) {
    return map[m];
  });
}

function formatEmailHeader(emailObject) {
  const { emailFrom, emailTo, emailSubject, emailDate, emailCc, emailBcc } = emailObject;
  const formattedDate = new Date(emailDate).toLocaleString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  // HTML header
  const headerHtml = `
      <div id="x_divRplyMsg" dir="ltr">
          <font face="Calibri, sans-serif" color="#000000" style="font-size:11pt">
              <b>From:</b> ${escapeHtml(emailFrom)}<br>
              <b>Sent:</b> ${formattedDate}<br>
              ${emailTo ? `<b>To:</b> ${escapeHtml(emailTo)}<br>` : ""}
              ${emailCc ? `<b>Cc:</b> ${escapeHtml(emailCc)}<br>` : ""}
              ${emailBcc ? `<b>Bcc:</b> ${escapeHtml(emailBcc)}<br>` : ""}
              <b>Subject:</b> ${escapeHtml(emailSubject)}
          </font>
          <div>&nbsp;</div>
      </div>
  `;

  return headerHtml;
}

export function addHeaderToEmailBody(emailObject, emailBodyContent) {
  const emailHeader = formatEmailHeader(emailObject);
  return emailHeader + emailBodyContent;
}
