import * as React from "react";
import PropTypes from "prop-types";
import { MenuItem, Select, InputLabel, FormControl, Box, FormHelperText } from "@mui/material";

export default class CustomSelect extends React.Component {
  render() {
    const { label, value, fontSize, mailItem, menuItem, onChangeFunc, helperText, error, onBlurFunc, defaultValue } =
      this.props;
    let modifiedLabel = label.replaceAll(" ", "").toLocaleLowerCase();

    return (
      <Box autoComplete="off" mb={3}>
        <FormControl fullWidth>
          <InputLabel id={`${modifiedLabel}-id-dropdown-input-label`} sx={{ fontSize: fontSize }} error={error}>
            {label}
          </InputLabel>
          <Select
            labelId={`${modifiedLabel}-id-dropdown`}
            id={`${modifiedLabel}-id-dropdown`}
            key={`${modifiedLabel}-id-dropdown`}
            value={value}
            label={label}
            onChange={(e) => (mailItem ? onChangeFunc(e, mailItem) : onChangeFunc(e, modifiedLabel))}
            onBlur={(e) => onBlurFunc(e, modifiedLabel)}
            fullWidth
            sx={{ fontSize: fontSize }}
            error={error}
            defaultValue={defaultValue}
          >
            {menuItem.map((item) => {
              return (
                <MenuItem key={item.key} value={item.key}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText error={error} id={`${modifiedLabel}-id-dropdown-helper-text`}>
            {helperText}
          </FormHelperText>
        </FormControl>
      </Box>
    );
  }
}

CustomSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChangeFunc: PropTypes.func,
  onBlurFunc: PropTypes.func,
  fontSize: PropTypes.number,
  menuItem: PropTypes.array,
  mailItem: PropTypes.any,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  defaultValue: PropTypes.string,
};
