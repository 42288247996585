import * as React from "react";
import PropTypes from "prop-types";
import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel } from "@mui/material";

export default class CustomCheckbox extends React.Component {
  changeAll = (e) => {
    const { onChangeFunc } = this.props;
    const isChecked = e.target.checked;
    onChangeFunc(e, { isChecked: isChecked, changeAll: true });
  };

  render() {
    const { label, checkboxitems, onChangeFunc } = this.props;
    const allSelected = checkboxitems.every((item) => item.isChecked);
    const someSelected = checkboxitems.some((item) => item.isChecked) && !allSelected;
    const showChangeAll = checkboxitems.length > 1;

    return (
      <>
        <Box autoComplete="off" mb={2}>
          <FormGroup>
            <FormLabel component="legend">{`Email Attachment${showChangeAll ? "s" : ""}`}</FormLabel>
            {showChangeAll && (
              <FormControlLabel
                control={<Checkbox checked={allSelected} indeterminate={someSelected} onChange={this.changeAll} />}
                label={allSelected ? "Unselect All" : "Select All"}
              />
            )}
            <Box sx={{ display: "flex", flexDirection: "column", ml: showChangeAll ? 3 : 0 }}>
              {checkboxitems.map((item, indx) => (
                <FormControlLabel
                  key={`${label}-${indx}`}
                  control={<Checkbox checked={item.isChecked} onChange={(e) => onChangeFunc(e, item)} />}
                  label={item.name}
                />
              ))}
            </Box>
          </FormGroup>
        </Box>
      </>
    );
  }
}

CustomCheckbox.propTypes = {
  label: PropTypes.string,
  checkboxitems: PropTypes.array.isRequired,
  onChangeFunc: PropTypes.func.isRequired,
};
