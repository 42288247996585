import React, { Component } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckIcon from "@mui/icons-material/Check";
import MailLockIcon from "@mui/icons-material/MailLock";

class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSteps: false,
      copiedToClipboard: false,
    };
  }

  openUrlInNewWindow = (url) => {
    window.open(url, "_blank");
  };

  copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.setState({ copiedToClipboard: true });

          setTimeout(() => {
            this.setState({ copiedToClipboard: false });
          }, 3000);
        })
        .catch((error) => {
          console.error("Failed to copy: ", error);
        });
    }
  };

  downloadManifest = (url) => {
    const displayName = process.env.manifest_display_name || "SEND TO EDS";
    const version = process.env.manifest_version || "LATEST";
    const sanitizedDisplayName = displayName.replace(/[\s.]/g, "_");
    const sanitizedVersion = version.replace(/[\s.]/g, "_");
    const xmlFileName = `${sanitizedDisplayName}_${sanitizedVersion}.xml`;
    const link = document.createElement("a");
    link.style.display = "none";
    document.body.appendChild(link);
    link.href = url;
    link.setAttribute("download", xmlFileName);
    link.click();

    document.body.removeChild(link);
  };

  toggleShowSteps = () => {
    this.setState((prevState) => ({ showSteps: !prevState.showSteps }));
  };

  render() {
    const { domainUnverified } = this.props;
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const manifestUrl = `${url.protocol}//${url.host}/manifest.xml`;
    const sideloadInstructionsUrl =
      "https://learn.microsoft.com/en-us/office/dev/add-ins/outlook/sideload-outlook-add-ins-for-testing?tabs=web#sideload-manually";

    const buttonStyle = {
      width: "100%",
      marginBottom: "10px",
      height: "100%",
    };

    const flexContainerStyle = {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    };

    const flexRowStyle = {
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      gap: "10px",
    };

    const buttonContainerStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    };

    return (
      <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "100px" }}>
        <Typography variant="h4" gutterBottom>
          Send to EDS
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to the home page of the plugin that enables you to send data to EDS through Outlook.
        </Typography>
        {domainUnverified ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "16px" }}>
            <MailLockIcon fontSize="large" />
            <Typography variant="body1" style={{ textAlign: "center", marginTop: "8px" }}>
              <strong>Domain is not verified.</strong>
              <br />
              Please verify your domain to access the plugin.
              <br /> <i>(@Accepted-Domain.com)</i>
            </Typography>
          </div>
        ) : (
          <div style={flexContainerStyle}>
            <div style={flexRowStyle}>
              <div style={{ flex: "70%", ...buttonContainerStyle }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.openUrlInNewWindow(manifestUrl)}
                  style={buttonStyle}
                >
                  Open Plugin Manifest
                </Button>
              </div>
              <div style={{ flex: "30%", ...buttonContainerStyle }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.copyToClipboard(manifestUrl)}
                  disabled={this.state.copiedToClipboard}
                  style={buttonStyle}
                  startIcon={this.state.copiedToClipboard ? <CheckIcon /> : <FileCopyIcon />}
                >
                  {this.state.copiedToClipboard ? "URL Copied!" : "Copy URL"}
                </Button>
              </div>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.downloadManifest(manifestUrl)}
              style={buttonStyle}
            >
              Download Manifest File
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.openUrlInNewWindow(sideloadInstructionsUrl)}
              style={buttonStyle}
            >
              Open Steps to Load Plugin Manually
            </Button>
            <Typography variant="body2" color="textSecondary" style={{ fontStyle: "italic" }}>
              <strong>Note:</strong> Use the "Copy URL" button to get the manifest URL
            </Typography>
          </div>
        )}
      </Container>
    );
  }
}

export default WelcomePage;
