import * as React from "react";
import PropTypes from "prop-types";
import Progress from "./Progress";
import { Alert, AlertTitle, Box, Container, LinearProgress, Typography } from "@mui/material";
import { LoadingButton as Button } from "@mui/lab";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomTextField from "./CustomTextField";
import CustomSelect from "./CustomSelect";
import CustomCheckbox from "./CustomCheckbox";
import { ENV_MAP, CATEGORIES, DEFAULT_ADJUSTER_ASSIGNMENT } from "../common/constants";
import {
  normalizeClaimNumbersAndReturnInSortedOrder,
  checkIsClaimnumberInvalid,
  checkIsInvalid,
  handleAttachments,
  postMultipleObjectsToS3,
  getDocSubType,
  getAdjusterDetails,
  parseSelectedAdjusterAndExposureId,
  getUsernameFromEmail,
  isValidEnv,
  getDomainFromEmail,
  normalizeClaimNumber,
  convertToBase64,
  addHeaderToEmailBody,
} from "../common/utils";
import Success from "./Success";
import WelcomePage from "./Welcome";

const fontSize = 13;

const defaultState = {
  mailContent: "",
  mailContentError: "",
  claimNumbers: [],
  attachments: [],
  metadata: {},
  category: "",
  claimnumber: "",
  environment: "",
  documenttype: "",
  assigntoadjuster: "",
  documenttitle: "",
  categoryInvalid: false,
  claimnumberInvalid: false,
  environmentInvalid: false,
  documenttypeInvalid: false,
  assigntoadjusterInvalid: false,
  documenttitleInvalid: false,
  adjusterList: DEFAULT_ADJUSTER_ASSIGNMENT,
  loadingGetAdjuster: false,
  successState: false,
  apiError: false,
  buttonLoading: false,
};

export default class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = defaultState;
  }

  checkAllFieldsAreValid = () => {
    const { claimnumber, environment, documenttitle, documenttype, assigntoadjuster } = this.state;
    return !!claimnumber && !!environment && !!documenttitle && !!documenttype && !!assigntoadjuster;
  };

  componentDidUpdate(_prevProps, prevState) {
    if (
      (prevState.claimnumber !== this.state.claimnumber || prevState.environment !== this.state.environment) &&
      !this.state.claimnumberInvalid &&
      !this.state.environmentInvalid
    ) {
      this.updateAdjuster();
    }
  }

  onChangeCategory = async (e, item) => {
    console.log({ pluginStageName: process.env.stage_name });
    console.log({ item });
    const attachments = handleAttachments(item);
    this.setState({ attachments: attachments });
    console.log({ attachments });
    item.body.getAsync(Office.CoercionType.Html, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        this.setState({ mailContent: result.value });
        let obj = [];
        const claimNumbers = normalizeClaimNumbersAndReturnInSortedOrder(result.value + " " + item.subject);
        claimNumbers.map((val) => obj.push({ value: val }));
        this.setState({ claimNumbers: obj });
      } else {
        this.setState({ mailContentError: bodyResult.error.message });
      }
    });
    this.setState({ category: e.target.value });
    this.setState({ categoryInvalid: checkIsInvalid(e.target.value) });

    if (process.env.stage_name.toLocaleLowerCase() !== "dev") {
      this.setState({ environment: "L4-1" });
      this.setState({ environmentInvalid: checkIsInvalid("L4-1") });
    }

    this.setState({ assigntoadjuster: "default_user:-1" });
  };

  updateState = (e, field) => {
    if (field === "claimnumber") {
      this.setState({ [field]: normalizeClaimNumber(e.target.value) });
    } else {
      this.setState({ [field]: e.target.value });
    }
    this.validateFields(e, field);
  };

  updateAdjuster = async () => {
    const { claimnumber, environment } = this.state;

    if (claimnumber && environment) {
      try {
        this.setState({ loadingGetAdjuster: true });
        const adjusterDetails = await getAdjusterDetails(claimnumber, environment);
        this.setState({ adjusterList: adjusterDetails, loadingGetAdjuster: false });
      } catch (error) {
        console.error("Error: ", error);
      }
    } else {
      console.log("claimnumber and environment not yet set.");
    }
  };

  validateFields = (e, field) => {
    let invalidFieldState = false;
    if (field === "claimnumber") {
      invalidFieldState = checkIsClaimnumberInvalid(e.target.value);
    } else if (field === "environment") {
      invalidFieldState = isValidEnv(e.target.value);
    } else {
      invalidFieldState = checkIsInvalid(e.target.value);
    }
    this.setState({ [`${field}Invalid`]: invalidFieldState });
  };

  updateAttachments = (e, item) => {
    let newAtt = [...this.state.attachments];

    if (item.changeAll) {
      newAtt = newAtt.map((att) => ({
        ...att,
        isChecked: item.isChecked,
      }));
    } else {
      for (let i in newAtt) {
        if (newAtt[i].id === item.id) {
          newAtt[i].isChecked = e.target.checked;
          break;
        }
      }
    }

    this.setState({ attachments: newAtt }, () => {
      console.log({ "Updated Attachments": this.state.attachments });
    });
  };

  resetState = () => {
    this.setState(defaultState);
  };

  getPurgeDate = () => {
    let currDate = new Date();
    let year = currDate.getFullYear();
    let month = currDate.getMonth() + 1;
    let date = currDate.getDate();
    let fullDateStr = month + "/" + date + "/" + year;
    fullDateStr = fullDateStr + " 2:00:00 AM";
    return fullDateStr;
  };

  getAttachmentsWithBase64 = () => {
    let attachmentsWithBase64Data = [];
    this.state.attachments
      ? this.state.attachments.map(
          (attach) =>
            attach.isChecked &&
            attachmentsWithBase64Data.push({
              name: attach.name,
              mimeType: attach.mimeType,
              hash: attach.hash,
              size: attach.size,
            }),
        )
      : [];
    return attachmentsWithBase64Data;
  };

  sendToEDS = async (item) => {
    this.setState({ buttonLoading: true });
    const { selectedAdjuster, selectedExposureId } = parseSelectedAdjusterAndExposureId(this.state.assigntoadjuster);
    const emailMetadata = {
      emailFrom: `${item.from.displayName} <${item.from.emailAddress}>`,
      emailTo: item.to.map((obj) => `${obj.displayName} <${obj.emailAddress}>`).join(", "),
      emailSubject: item.subject,
      emailDate: item.dateTimeCreated,
      emailCc: item.cc.map((obj) => `${obj.displayName} <${obj.emailAddress}>`).join(", "),
      emailBcc: item.bcc.map((obj) => `${obj.displayName} <${obj.emailAddress}>`).join(", "),
      emailMessageId: item.internetMessageId,
    };

    const attachmentFiles = this.getAttachmentsWithBase64();
    const subEnv = this.state.environment.toUpperCase();
    const emailWithHeader = addHeaderToEmailBody(emailMetadata, this.state.mailContent);

    const edsMetadata = {
      originalFileName: item.subject + ".htm",
      fileName: this.state.documenttitle + ".htm",
      addedBy: getUsernameFromEmail(Office.context.mailbox.userProfile.emailAddress),
      // addedBy: "natarajan.parvathirajan",
      subEnv: subEnv,
      documentType: this.state.category,
      documentSubtype: this.state.documenttype,
      purgeDate: this.getPurgeDate(),
      claimNumber: this.state.claimnumber.toUpperCase(),
      emailData: convertToBase64(emailWithHeader),
      emailMetadata: emailMetadata,
      selectedAdjuster: selectedAdjuster,
      selectedExposureId: selectedExposureId,
      attachments: attachmentFiles,
      fullEmailContent: null,
      isOutlook: "Y",
    };
    const responsePayload = JSON.stringify(edsMetadata);
    console.log({ edsMetadata });
    let error = undefined;
    let response = undefined;
    try {
      response = await postMultipleObjectsToS3(responsePayload, subEnv);
    } catch (err) {
      error = err;
      console.log("ERROR ", err);
    }
    if (response === "Accepted payload" && !error) {
      this.setState({ successState: true, buttonLoading: false });
    } else {
      this.setState({ apiError: true, buttonLoading: false });
    }
  };

  render() {
    const isButtonDisabled = !this.checkAllFieldsAreValid();
    const { title, isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return (
        <Progress
          title={title}
          logo={"https://www.stateauto.com/public/files/sa_favicon.ico.ico"}
          message="Load your add-in"
        />
      );
    }

    const item = Office.context.mailbox && Office.context.mailbox.item;
    const allowedDomains = ["libertymutual.com", "safeco.com"];
    const userDomain = item && getDomainFromEmail(Office.context.mailbox.userProfile.emailAddress).toLowerCase();
    const domainUnverified = item && !allowedDomains.includes(userDomain);

    const showForm = item && !this.state.successState && !domainUnverified;

    return showForm ? (
      <>
        <Container maxWidth="sm">
          <CustomTextField
            label="SUBJECT"
            defaultValue={item.subject || ""}
            fontSize={fontSize}
            inputProps={{ readOnly: true, disableUnderline: true }}
          />

          <CustomTextField
            label="FROM"
            defaultValue={item.from.displayName || ""}
            fontSize={fontSize}
            inputProps={{ readOnly: true, disableUnderline: true }}
          />

          <CustomSelect
            value={this.state.category}
            label="Category"
            mailItem={item}
            menuItem={CATEGORIES}
            onChangeFunc={this.onChangeCategory}
            onBlurFunc={this.validateFields}
            error={this.state.categoryInvalid}
            helperText={this.state.categoryInvalid ? "Please Select the category to proceed further" : ""}
          />

          {this.state.category && (
            <>
              <CustomAutoComplete
                label="Environment"
                defaultValue={this.state.environment}
                onBlurFunc={this.updateState}
                fontSize={fontSize}
                valueMap={ENV_MAP}
                error={this.state.environmentInvalid}
                helperText={`${this.state.environmentInvalid ? "Enter a valid environment " : ""}e.g., L1-1`}
              />

              <CustomAutoComplete
                label="Claim Number"
                onBlurFunc={this.updateState}
                fontSize={fontSize}
                valueMap={this.state.claimNumbers}
                error={this.state.claimnumberInvalid}
                helperText={`${
                  this.state.claimnumberInvalid ? "Enter a valid Claim Number " : ""
                }e.g., AU-0000000-XXXXXX or AU-XXXXXX`}
              />

              <CustomAutoComplete
                label="Document Title"
                onBlurFunc={this.updateState}
                fontSize={fontSize}
                valueMap={[{ value: "Document Title" }]}
                error={this.state.documenttitleInvalid}
                helperText={this.state.documenttitleInvalid ? "Please select the Document Title" : ""}
              />

              <CustomSelect
                value={this.state.documenttype}
                label="Document Type"
                menuItem={getDocSubType(this.state.category)}
                onChangeFunc={this.updateState}
                onBlurFunc={this.validateFields}
                error={this.state.documenttypeInvalid}
                helperText={this.state.documenttypeInvalid ? "Please select the Document Type" : ""}
              />

              {this.state.claimnumber &&
                this.state.environment &&
                !this.state.claimnumberInvalid &&
                !this.state.environmentInvalid &&
                !this.state.loadingGetAdjuster && (
                  <CustomSelect
                    value={this.state.assigntoadjuster}
                    label="Assign to Adjuster"
                    menuItem={this.state.adjusterList}
                    defaultValue={this.state.assigntoadjuster}
                    onChangeFunc={this.updateState}
                    onBlurFunc={this.validateFields}
                    error={this.state.assigntoadjusterInvalid}
                    helperText={this.state.assigntoadjusterInvalid ? "Please select the Adjuster" : ""}
                  />
                )}

              {this.state.loadingGetAdjuster && (
                <Box mb={2} sx={{ textAlign: "center" }}>
                  <Alert severity="info">
                    <AlertTitle>Fetching Adjuster details</AlertTitle>
                    <Typography variant="body2">Please wait or click "Send to EDS" to continue.</Typography>
                  </Alert>
                  <LinearProgress />
                </Box>
              )}

              {this.state.attachments.length > 0 && (
                <CustomCheckbox
                  label="attachments"
                  checkboxitems={this.state.attachments}
                  onChangeFunc={this.updateAttachments}
                />
              )}

              <Box pt={4}>
                <Button fullWidth variant="outlined" onClick={this.resetState}>
                  Back
                </Button>
              </Box>

              <Box pb={5} pt={2}>
                <Button
                  loading={this.state.buttonLoading}
                  disabled={isButtonDisabled}
                  fullWidth
                  variant={this.state.apiError ? "outlined" : "contained"}
                  color={this.state.apiError ? "error" : undefined}
                  onClick={() => this.sendToEDS(item)}
                >
                  {this.state.apiError ? "Retry Send" : "Send to EDS"}
                </Button>
                {(isButtonDisabled || this.state.apiError) && (
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: 10,
                    }}
                    mt={1}
                    ml={2}
                    mr={2}
                    color={this.state.apiError ? "error" : undefined}
                  >
                    {this.state.apiError
                      ? "Something went wrong! Please retry or Try again later. Please contact the help desk if you have any questions."
                      : "Note: Please fill all the form values to proceed"}
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Container>
      </>
    ) : this.state.claimnumber ? (
      <Success environment={this.state.environment} ClaimNumber={this.state.claimnumber} />
    ) : (
      <WelcomePage domainUnverified={domainUnverified} />
    );
  }
}

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};
