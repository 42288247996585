import * as React from "react";
import PropTypes from "prop-types";
import { Spinner, SpinnerSize } from "@fluentui/react";

export default class Progress extends React.Component {
  render() {
    const { logo, message, title } = this.props;

    return (
      <section className="ms-welcome__progress ms-u-fadeIn500">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
            <img width="90" height="90" src={logo} alt={title} title={title} />
            <h1 className="ms-fontSize-su ms-fontWeight-light ms-fontColor-neutralPrimary">{title}</h1>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Spinner size={SpinnerSize.large} label={message} />
          </div>
        </div>
      </section>
    );
  }
}

Progress.propTypes = {
  logo: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
};
